import React, { useEffect, useState } from "react";
import amberSabic from "../../../static/images/amber-sabic.svg";
import forwardIcon from "../../../static/images/forward-icon.svg";
import axios from '../../helper/Axios';

const DemoChatComp = ({isHelpdesk = false}) => {
    const [isBtnClicked, setIsBtnClicked] = useState(false);

    const fetchChatLink = async () => {
        setIsBtnClicked(true);
        const response = await axios.get(`get_random_chat_link?client_id=${777}`);

        if (response && response.data && response.data.data && response.data.data.chat_url) {
            let redirectURL = '';
            if (isHelpdesk) {
                const urlArr = response.data.data.chat_url.split('?');
                redirectURL = urlArr[0];
            }
            window.location.replace(isHelpdesk ? `${redirectURL}/query` : response.data.data.chat_url);
        }
    }

    useEffect(() => {
        document.title = isHelpdesk ? 'Demo Helpdesk' : 'Demo Chat';
    }, [])

return (
    <div className="sabic-demo-chat-container">
        <img src={amberSabic} alt="Chat-bot" />
        <div className="sabic-dc-text">Hi{isHelpdesk ? '' : ", I'm "}{isHelpdesk ? '' : <span className="sabic-colored-text">Amber</span>}!</div>
        <div className="sabic-dc-text">Are you ready to {isHelpdesk ? 'know more about Amber?' : 'experience a demo chat with me?'}</div>
        <button disabled={isBtnClicked} onClick={fetchChatLink} className="sabic-button ">{isBtnClicked ? 'Redirecting...' : 'Start Chatting '}{isBtnClicked ? null : <img src={forwardIcon} alt="forward icon" />}</button>
    </div>
);
}

export default DemoChatComp;